$base-typing-speed: 300ms;
$code-typing-animation-duration: 1200ms;

.content {
  position: relative;
}

.container {
  top: 0; bottom: 0; left: 0; right: 0;
}

#bongo-cat {
  position: absolute;
  height: 80vh;
  width: 80vw;
  top: 50vh; bottom: 0; left: 0; right: 0;
  margin: auto;
}

.typing-animation {
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: $code-typing-animation-duration;
}

@for $line from 1 through 9 {
  $fn-group: ceil($line / 3);
  path#f#{$fn-group}-l#{$line} {
    animation-name: typing-f#{$fn-group}-l#{$line}
  }
}

@keyframes typing-f3-l9 {
  0% { d: path('M8,25L8,25') }
  82% { d: path('M8,25L8,25') }
  92% { d: path('M8,25L96,25') }
  100% { d: path('M8,25L96,25') }
}

@keyframes typing-f3-l8 {
  0% { d: path('M8,13L8,13') }
  68% { d: path('M8,13L8,13') }
  82% { d: path('M8,13L146,13') }
  100% { d: path('M8,13L146,13') }
}

@keyframes typing-f3-l7 {
  0% { d: path('M0,1L0,1') }
  60% { d: path('M0,1L0,1') }
  68% { d: path('M0,1L96,1') }
  100% { d: path('M0,1L96,1') }
}

@keyframes typing-f2-l6 {
  0% { d: path('M8,25L8,25') }
  54% { d: path('M8,25L8,25') }
  60% { d: path('M8,25L69,25') }
  100% { d: path('M8,25L69,25') }
}

@keyframes typing-f2-l5 {
  0% { d: path('M8,13L8,13') }
  44% { d: path('M8,13L8,13') }
  54% { d: path('M8,13L114,13') }
  100% { d: path('M8,13L114,13') }
}

@keyframes typing-f2-l4 {
  0% { d: path('M0,1L0,1') }
  30% { d: path('M0,1L0,1') }
  44% { d: path('M0,1L136,1') }
  100% { d: path('M0,1L136,1') }
}

@keyframes typing-f1-l3 {
  0% { d: path('M8,25L8,25') }
  24% { d: path('M8,25L8,25') }
  30% { d: path('M8,25L61,25') }
  100% { d: path('M8,25L61,25') }
}

@keyframes typing-f1-l2 {
  0% { d: path('M8,13L8,13') }
  14% { d: path('M8,13L8,13') }
  24% { d: path('M8,13L124,13') }
  100% { d: path('M8,13L124,13') }
}

@keyframes typing-f1-l1 {
  0% { d: path('M0,1L0,1') }
  14% { d: path('M0,1L160,1') }
  100% { d: path('M0,1L160,1') }
}

#paw-right--up, 
#paw-right--down, 
#paw-left--up,
#paw-left--down {
  animation: blink $base-typing-speed infinite;
}

#paw-right--up,
#paw-left--down{
  animation-delay: $base-typing-speed/2;
}

@keyframes blink {
  0% { opacity: 0; }
  49% { opacity: 0; }
  50% { opacity: 1; }
}

#laptop__code {
  transform: rotateX(-37deg) rotateY(-46deg) rotateZ(-23deg) translateX(8px) translateY(20px) translateZ(-50px);
}
#laptop__cover {
    mix-blend-mode: hard-light;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 9999; /* Make sure it's on top of other content */
    background-color: rgba(0, 0, 0, 0.25); /* Semi-transparent overlay */
  }
  
  .centered-content {
    color: black;
    font-size: 3rem;
  }
  